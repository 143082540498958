<template>
  <CRow v-cloak>
    <CCol sm="12" class="mt-4">
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </CAlert>
      </div>

      <form v-on:submit.prevent="downloadBills()" class="pb-5">
        <CRow>
          <CCol cols="12" :md="3">
            <div class="form-group">
              <label>Bill Month<sup class="text-danger">*</sup></label>
              <select
                name="month"
                v-model="search.bill_month"
                class="form-control"
                required
              >
                <option value>Select Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </div>
          </CCol>
          <CCol cols="12" :md="3">
            <div class="form-group">
              <label>Bill Year<sup class="text-danger">*</sup></label>
              <select
                name="year"
                v-model="search.bill_year"
                class="form-control"
                required
              >
                <option value>Select Year</option>
                <option v-for="year in years" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
          </CCol>
          <CCol cols="12" :md="3">
            <label>Status</label>
            <div class="form-group">
              <select
                name="status"
                v-model="search.status"
                class="form-control"
              >
                <option value>Select Status</option>
                <option value="PAID">PAID</option>
                <option value="DUE">DUE</option>
              </select>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="mx-auto mt-4" cols="12" :md="3">
            <button :disabled="loading" type="submit" class="w-100 btn btn-primary">
              {{ loading ? 'Downloading...' : 'Download' }}
            </button>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12">
            <div class="table-responsive">
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th class="font-weight-bold">Processing Time</th>
                  <th class="font-weight-bold">Processed Time</th>
                  <th class="font-weight-bold">Bill Year</th>
                  <th class="font-weight-bold">Bill Month</th>
                  <th class="font-weight-bold">Download</th>
                </tr>
                </thead>
                <tbody v-if="downloadBillLists.length">
                <tr v-for="bill in downloadBillLists" :key="bill.id">
                  <td>{{ bill.starting_time }}</td>
                  <td>{{ bill.finished_time }}</td>
                  <td>{{ bill.bill_year }}</td>
                  <td>{{ bill.bill_month }}</td>
                  <td>
                    <a v-if="bill.status == 'success'" :href="bill.file_path" download target="_blank">Download File</a>
                    <span v-else>{{ bill.status }}</span>
                  </td>
                </tr>
                </tbody>

                <tbody v-else>
                <tr>
                  <td colspan="10">
                    <h5 class="text-center">Data Not Available.</h5>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </CCol>
        </CRow>
      </form>
    </CCol>
  </CRow>
</template>

<script>
import {mapGetters} from "vuex";
import Pusher from "pusher-js";

export default {
  name: "BillDownloadModal",
  data() {
    return {
      loading: false,
      search: {
        status: "",
        bill_month: "",
        bill_year: "",
      },
      serverErrors: [],
      // downloadBillLists: this.$store.state.Bill.downloadBillLists,
      showDismissibleAlert: false,
    };
  },
  computed: {
    ...mapGetters("Bill", ["downloadBillLists"]),
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2014 },
        (value, index) => 2015 + index
      );
    },
  },
  created() {
    const self = this; // Store the outer context in a variable

    const pusher = new Pusher(`${process.env.VUE_APP_PUSHER_API_KEY}`, {
      cluster: 'ap2'
    });

    const channel = pusher.subscribe('multi-bills-pdf-generate-channel');
    channel.bind('multi-bills-pdf-generate-event', function (data) {
      let downloadBillList = data.downloadBillList
      // Find the index of the object to replace
      let index = self.downloadBillLists.findIndex(obj => obj.id === downloadBillList.id);
      // Replace the object if found
      if (index !== -1) {
        self.downloadBillLists[index] = downloadBillList;
        self.$forceUpdate();
      }
      self.loading = false;
    });
  },
  methods: {
    downloadBills() {
      this.loading = true;
      this.serverErrors = [];
      this.$store.dispatch("Bill/downloadMultipleBill", this.search).then(
        () => {
          //
        },
        (err) => {
          const res = JSON.parse(
            Buffer.from(err.response.data).toString("utf8")
          );
          this.$toastr.e(res.message, "Failed!");
          this.$store.dispatch("Settings/loading", false);

          if (err.response.status === 422) {
            this.showDismissibleAlert = true;
            let errors = res.errors;
            for (let field of Object.keys(errors)) {
              this.serverErrors.push(errors[field][0]);
            }
          }
        }
      );
    }
  },
  mounted() {
    this.$store.dispatch("Bill/downloadMultipleFileLists", this.search);
  }
};
</script>

<style scoped lang="scss">
//
</style>
