<template>
  <div>
    <CRow class="pb-2">
      <CCol sm="3">
        <h4 class="text-uppercase">Billing Generation</h4>
      </CCol>
      <CCol sm="9" class="text-right">
        <router-link class="btn btn-success text-white" :to="{name:'CreateBill'}">
          <i class="fa fa-plus"></i> Add new
        </router-link>
        <router-link :to="{name:'ImportBill'}" class="btn btn-dark mx-2">
          <i class="fa fa-upload"></i> Import
        </router-link>
        <button type="button" class="btn btn-primary" @click="showDownloadModal = true">Download Multiple Bills</button>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow>
            <CCol sm="12" v-if="checkUsrPermissions(['Bill_all', 'Bill_search'])">
              <form v-on:submit.prevent="searchBilling(search)">
                <CRow>
                  <CCol cols="12" :md="2">
                    <div class="form-group">
                      <input
                        type="text"
                        name="customer_name"
                        placeholder="Consumer name .."
                        v-model="search.customer_name"
                        class="form-control"
                      />
                    </div>
                  </CCol>
                  <CCol cols="12" :md="2">
                    <div class="form-group">
                      <input
                        type="text"
                        name="meter_serial_no"
                        placeholder="Meter no .."
                        v-model="search.meter_serial_no"
                        class="form-control"
                      />
                    </div>
                  </CCol>
                  <CCol cols="12" :md="2">
                    <div class="form-group">
                      <select
                        name="month"
                        v-model="search.month"
                        class="form-control"
                      >
                        <option value>Select Month</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                    </div>
                  </CCol>
                  <CCol cols="12" :md="2">
                    <div class="form-group">
                      <select
                        name="year"
                        v-model="search.year"
                        class="form-control"
                      >
                        <option value>Select Year</option>
                        <option v-for="year in years" :key="year" :value="year">
                          {{ year }}
                        </option>
                      </select>
                    </div>
                  </CCol>
                  <CCol cols="12" :md="1">
                    <div class="form-group">
                      <select
                        name="status"
                        v-model="search.status"
                        class="form-control"
                      >
                        <option value>Select Status</option>
                        <option value="PAID">PAID</option>
                        <option value="DUE">DUE</option>
                      </select>
                    </div>
                  </CCol>
                  <CCol cols="12" :md="1">
                    <div class="form-group">
                      <select
                        name="status"
                        v-model="search.bill_type"
                        class="form-control"
                      >
                        <option value>Select bill type</option>
                        <option value="new">New</option>
                        <option value="old">Old</option>
                      </select>
                    </div>
                  </CCol>
                  <CCol cols="12" :md="2">
                    <button type="submit" class="w-100 btn btn-success">
                      Search
                    </button>
                  </CCol>
                </CRow>
              </form>
            </CCol>

            <CCol sm="12">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th class="font-weight-bold">Consumer Name</th>
                      <th class="font-weight-bold">Bill Number</th>
                      <th class="font-weight-bold">Meter Serial No</th>
                      <th class="font-weight-bold">Meter reading</th>
                      <th class="font-weight-bold">Used Unit</th>
                      <th class="font-weight-bold">Total Amount</th>
                      <th class="font-weight-bold">Status</th>
                      <th class="fon-weight-bold">Billing Month</th>
                      <th class="font-weight-bold">Issued At</th>
                      <th class="font-weight-bold" v-if="checkUsrPermissions(['Bill_all', 'Bill_download'])">Actions</th>
                    </tr>
                  </thead>

                  <tbody v-if="bills.length">
                    <tr v-for="bill in bills" :key="bill.id">
                      <td>{{ bill.customer_salutation }} {{ bill.customer_name }}</td>
                      <td>{{ bill.bill_number }}</td>
                      <td>
                        <router-link
                          class="text-info"
                          :to="'/meters/' + bill.meter_id"
                          >{{ bill.meter_serial_no }}</router-link
                        >
                      </td>
                      <td>{{ bill.present_reading }}</td>
                      <td>{{ bill.used_unit }}</td>
                      <td>{{ bill.total_amount }}</td>
                      <td>{{ bill.status }}</td>
                      <td>
                        {{ months[bill.bill_month] }}, {{ bill.bill_year }}
                      </td>
                      <td>{{ bill.issue_date | moment }}</td>
                      <td v-if="checkUsrPermissions(['Bill_all', 'Bill_download'])">
                        <a
                          v-if="checkUsrPermissions(['Bill_all', 'Bill_download'])"
                          href="javascript:void(0)"
                          class="download-bill btn btn-primary btn-sm"
                          @click="downloadBill(bill)"
                          >Download</a
                        >
                        <router-link :to="{name:'EditBill', params:{id:bill.id}}" class="btn btn-warning btn-sm ml-2 text-white">Edit</router-link>
                        &nbsp;
                        <br />
                        <!-- <router-link :to="'/bills/'+bill.id+'/consumptions'" class="btn btn-info btn-sm">View</router-link> &nbsp;                   -->
                      </td>
                    </tr>
                  </tbody>

                  <tbody v-else>
                    <tr>
                      <td colspan="10">
                        <h5 class="text-center">Billing Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
          <CPagination
            v-if="bills.length"
            align="center"
            :pages="rows"
            :active-page.sync="search.currentPage"
            @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>
    <CModal
        title="Download Multiple Bills"
        color="default"
        class="hide-footer custom-close-button"
        :show.sync="showDownloadModal"
        size="xl"
      >
        <BillDownloadModal
          @closeDownloadModal="closeBillDownloadModal"
        ></BillDownloadModal>
      </CModal>
  </div>
</template>

<script>
import BillDownloadModal from "./partials/BillDownloadModal";
import { mapGetters } from "vuex";
export default {
  name: "Bill",
  data: () => {
    return {
      search: {
        month: "",
        year: "",
        status: "",
        bill_type: "",
        customer_name: "",
        meter_serial_no: "",
        currentPage: 1,
      },
      isMobile: Boolean,
      isMobileOnly: Boolean,
      // bills: [],
      deletingState: false,
      loadingState: false,
      // role: {},
      serverErrors: [],
      showDismissibleAlert: false,
      showDownloadModal: false,
      months: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      },
      // isLoading: false,
      // rows: 1,
      // perPage: 10,
      // currentPage: 1,
    };
  },
  components: {
    BillDownloadModal
  },
  computed: {
    ...mapGetters("Bill", ["bills", "rows", "perPage"]),
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2014 },
        (value, index) => 2015 + index
      );
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD MMM, YYYY");
    },
  },
  methods: {
    closeBillDownloadModal() {
      this.showDownloadModal = false;
    },
    downloadBill(bill) {
      this.$store.dispatch("Bill/downloadBill", bill);
    },
    searchBilling(search) {
      search.currentPage = 1;
      this.$router.replace({name: "Bills", query: this.search});
      this.$store.dispatch("Bill/searchBilling", search);
    },
    handlePageChange(value) {
      this.search.currentPage = value;
      this.$router.replace({name: "Bills", query: this.search});
      if (this.search.customer_name || this.search.meter_serial_no || this.search.month || this.search.year || this.search.status || this.search.bill_type) {
        this.$store.dispatch("Bill/searchBilling", this.search);
      } else {
        this.$store.dispatch("Bill/getBills", this.search);
      }
    },
    setSearchParams() {
      this.search.month = this.$route.query.month?this.$route.query.month:'';
      this.search.year = this.$route.query.year?this.$route.query.year:'';
      this.search.status = this.$route.query.status?this.$route.query.status:'';
      this.search.currentPage = this.$route.query.currentPage?parseInt(this.$route.query.currentPage):1;
      this.search.customer_name = this.$route.query.customer_name?this.$route.query.customer_name:'';
      this.search.meter_serial_no = this.$route.query.meter_serial_no?this.$route.query.meter_serial_no:'';
      this.search.bill_type = this.$route.query.bill_type ? this.$route.query.bill_type : '';
    }
  },
  mounted() {
    this.setSearchParams();
    this.$store.dispatch("Bill/searchBilling", this.search);
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.download-button-width {
  width: 100%;
}
.hide-footer >>> footer {
  display: none;
}
.custom-close-button >>> .close {
  /* color: aliceblue; */
  font-size: 26px;
}
</style>

<style scoped>
.custom-button {
  color: #000;
  padding: 10px 8px 10px 15px;
  font-size: 15px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
  line-height: 1;
}

.card {
  border: none;
  padding: 15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}
</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
        td:nth-child(2) {
          color: #5e76e7;
        }
        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>
